import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import WebIcon from "../svg/WebIcon"
import { Subheader } from './PageTitles';

export default function OuterLinks(props) {
    const { linkData, title, nameContext, useIcon } = props;

    const isEmpty = Object.values(linkData).every(x => x === null || x === '');

    if (isEmpty) return null

    let titleArr = '';

    if (nameContext) titleArr = `${nameContext} `;

    return (
        <div className={`p-4`}>
            <div className={`flex justify-start flex-wrap flex-row items-center`}>

                    {title &&
                        <div className={`w-full mb-3`}>
                            <Subheader>{title}</Subheader>
                        </div>
                    }

                    <div className={`inline-flex justify-start md:text-2xl ${useIcon === true ? 'flex-row items-center space-x-4' : 'flex-col items-start md:space-y-1 space-y-2'}`}>

                    {linkData.facebookartist &&
                        <OutboundLink className={`${useIcon === true ? 'hover:text-primary-default' : 'hover:underline underline-offset-[3px] decoration-1 uppercase text-xxs font-mono tracking-wide'}`} target={`_blank`} rel={`noopener noreferrer`} href={linkData.facebookartist} title={`Follow ${titleArr}on Facebook`}>
                            {useIcon === true ? (
                                <WebIcon name={`facebook`} />
                            ) : (<>
                                Facebook
                            </> )}
                        </OutboundLink>
                    }

                    {linkData.instagramartist &&
                        <OutboundLink className={`${useIcon === true ? 'hover:text-primary-default' : 'hover:underline underline-offset-[3px] decoration-1 uppercase text-xxs font-mono tracking-wide'}`} target={`_blank`} rel={`noopener noreferrer`} href={linkData.instagramartist} title={`Follow ${titleArr}on Instagram`}>
                            
                            {useIcon === true ? (
                                <WebIcon name={`instagram`} />
                            ) : ( <>
                                Instagram
                            </> )}
                        </OutboundLink>
                    }

                    {linkData.twitterartist &&
                        <OutboundLink className={`${useIcon === true ? 'hover:text-primary-default' : 'hover:underline underline-offset-[3px] decoration-1 uppercase text-xxs font-mono tracking-wide'}`} target={`_blank`} rel={`noopener noreferrer`} href={linkData.twitterartist} title={`Follow ${titleArr}on Twitter`}>
                            {useIcon === true ? (
                               <WebIcon name={`twitter`} />
                            ) : (<>
                                Twitter
                            </> )}
                        </OutboundLink>
                    }

                    {linkData.websiteartist &&
                        <OutboundLink className={`${useIcon === true ? 'hover:text-primary-default' : 'hover:underline underline-offset-[3px] decoration-1 uppercase text-xxs font-mono tracking-wide'}`} target={`_blank`} rel={`noopener noreferrer`} href={linkData.websiteartist} title={`${titleArr}Official Website`}>
                            
                            {useIcon === true ? (
                               <WebIcon name={`website`} />
                            ) : (<>
                                Website
                            </>)}
                        </OutboundLink>
                    }


                    {linkData.spotifyartist &&
                        <OutboundLink className={`${useIcon === true ? 'hover:text-primary-default' : 'hover:underline underline-offset-[3px] decoration-1 uppercase text-xxs font-mono tracking-wide'}`} target={`_blank`} rel={`noopener noreferrer`} href={linkData.spotifyartist} title={`Stream ${titleArr}on Spotify`}>
                            
                            {useIcon === true ? (
                               <WebIcon name={`spotify`} />
                            ) : (<>
                                Spotify
                            </>)}
                        </OutboundLink>
                    }

                    {linkData.appleartist &&
                        <OutboundLink className={`${useIcon === true ? 'hover:text-primary-default' : 'hover:underline underline-offset-[3px] decoration-1 uppercase text-xxs font-mono tracking-wide'}`} target={`_blank`} rel={`noopener noreferrer`} href={linkData.appleartist} title={`Stream ${titleArr}on Apple Music`}>
                           
                            {useIcon === true ? (
                               <WebIcon name={`apple`} />
                            ) : (<>
                                Apple Music
                            </>)}
                        </OutboundLink>
                    }
                    {linkData.youtubeartist &&
                        <OutboundLink className={`${useIcon === true ? 'hover:text-primary-default' : 'hover:underline underline-offset-[3px] decoration-1 uppercase text-xxs font-mono tracking-wide'}`} target={`_blank`} rel={`noopener noreferrer`} href={linkData.youtubeartist} title={`Stream ${titleArr}on YouTube`}>
                            
                            {useIcon === true ? (
                               <WebIcon name={`youtube`} />
                            ) : (<>
                                YouTube
                            </>)}
                        </OutboundLink>
                    }
                    {linkData.amazonartist &&
                        <OutboundLink className={`${useIcon === true ? 'hover:text-primary-default' : 'hover:underline underline-offset-[3px] decoration-1 uppercase text-xxs font-mono tracking-wide'}`} target={`_blank`} rel={`noopener noreferrer`} href={linkData.amazonartist} title={`Stream ${titleArr}on Amazon Music`}>
                            
                            {useIcon === true ? (
                               <WebIcon name={`amazon`} />
                            ) : (<>
                               Amazon Music
                               </>)}
                        </OutboundLink>
                    }
                    {linkData.soundcloudartist &&
                        <OutboundLink className={`${useIcon === true ? 'hover:text-primary-default' : 'hover:underline underline-offset-[3px] decoration-1 uppercase text-xxs font-mono tracking-wide'}`} target={`_blank`} rel={`noopener noreferrer`} href={linkData.soundcloudartist} title={`Stream ${titleArr}on SoundCloud`}>
                            
                            {useIcon === true ? (
                              <WebIcon name={`soundcloud`} />
                            ) : (<>
                                SoundCloud
                            </>)}
                        </OutboundLink>
                    }
                    {linkData.pandoraartist &&
                        <OutboundLink className={`${useIcon === true ? 'hover:text-primary-default' : 'hover:underline underline-offset-[3px] decoration-1 uppercase text-xxs font-mono tracking-wide'}`} target={`_blank`} rel={`noopener noreferrer`} href={linkData.pandoraartist} title={`Stream ${titleArr}on Pandora`}>
                            
                            {useIcon === true ? (
                              <WebIcon name={`pandora`} />
                            ) : (<>
                                Pandora
                            </>)}
                        </OutboundLink>
                    }

                    {linkData.bandcampartist &&
                        <OutboundLink className={`${useIcon === true ? 'hover:text-primary-default' : 'hover:underline underline-offset-[3px] decoration-1 uppercase text-xxs font-mono tracking-wide'}`} target={`_blank`} rel={`noopener noreferrer`} href={linkData.bandcampartist} title={`Stream ${titleArr}on Bandcamp`}>
                            
                            {useIcon === true ? (
                              <WebIcon name={`bandcamp`} />
                            ) : (<>
                                Bandcamp
                            </>)}
                        </OutboundLink>
                    }
                    {linkData.itunesartist &&
                        <OutboundLink className={`${useIcon === true ? 'hover:text-primary-default' : 'hover:underline underline-offset-[3px] decoration-1 uppercase text-xxs font-mono tracking-wide'}`} target={`_blank`} rel={`noopener noreferrer`} href={linkData.itunesartist} title={`Stream ${titleArr}on iTunes`}>
                            
                            {useIcon === true ? (
                              <WebIcon name={`itunes`} />
                            ) : (<>
                                iTunes
                            </>)}
                        </OutboundLink>
                    }
                    {linkData.merchartist &&
                        <OutboundLink className={`${useIcon === true ? 'hover:text-primary-default' : 'hover:underline underline-offset-[3px] decoration-1 uppercase text-xxs font-mono tracking-wide'}`} target={`_blank`} rel={`noopener noreferrer`} href={linkData.merchartist} title={`${titleArr}Official Merch `}>
                            
                            {useIcon === true ? (
                              <WebIcon name={`merch`} />
                            ) : (<>
                                Merch
                            </>)}
                        </OutboundLink>
                    }

                    </div>
                    </div>
        </div>
    )
}

OuterLinks.defaultProps = {
    useIcon: true
}